import Link from 'next/link';
import React from 'react';

import { Flex, Image, Text, useColorMode } from '@chakra-ui/react';

export function RekaLink({ href, onClick, minimize }: { href: string; onClick?: () => void; minimize?: boolean }) {
    const { colorMode } = useColorMode();
    const handleClick = (e: React.MouseEvent) => {
        if (!onClick) return;
        e.preventDefault();
        onClick();
    };
    return (
        <Link href={href} onClick={handleClick}>
            <Flex display={'inline-flex'} gap={'8px'} alignItems={'center'} fontSize={'18px'}>
                <Image
                    filter={colorMode === 'dark' ? '' : 'invert(1)'}
                    src={'/favicon.png'}
                    role="presentation"
                    w={6}
                    h={6}
                />{' '}
                <Text fontWeight={600} fontFamily={'monospace'}>
                    <Text
                        borderBottom={'1px solid'}
                        borderColor={'border-main'}
                        textTransform={'uppercase'}
                        as={'span'}
                    >
                        Nexus
                    </Text>
                </Text>
            </Flex>
        </Link>
    );
}
