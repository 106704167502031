import fetch from 'isomorphic-unfetch';

import { configValue } from '@/config/config-value';
import { compact } from '@/utils/helper';
import { Message } from '@/utils/types';
import { captureException, captureMessage } from '@sentry/nextjs';

import { SSE } from '../../lib/sse';

export interface IChatParams {
    human: string;
    mediaUrl?: string;
    mediaType?: string;
    fileUrl?: string;
    conversationHistory?: Message[];
    retrievalDataset?: string;
    modelName?: string;
    randomSeed?: number;
    useCodeInterpreter?: boolean;
    useSearchEngine?: boolean;
    requestOutputLen?: number;
    temperature?: number;
    runtimeTopK?: number;
    runtimeTopP?: number;
    repetitionPenalty?: number;
    lenPenalty?: number;
    stopTokens?: string[];
    assistantStartText?: string;
    persona?: Persona;
    mode?: null | 'perception' | 'pdf' | 'speech2speech';
    title?: string;
}

type PayloadType = {
    conversation_history: Message[];
    random_seed?: number;
    stream: boolean;
    use_search_engine?: boolean;
    use_code_interpreter?: boolean;
    retrieval_dataset?: string;
    file_url?: string;
    model_name: string;
    request_output_len?: number;
    temperature?: number;
    runtime_top_k?: number;
    runtime_top_p?: number;
    repetitionPenalty?: number;
    len_penalty?: number;
    stop_tokens?: string[];
    assistant_start_text?: string;
};

export type Persona = {
    systemPrompt: string;
    assistantStartText: string;
    searchKeywords?: string;
    voiceId: string;
};

export interface PersonaEntry {
    name: string;
    persona: Persona;
}

export class TTS {
    private readonly ws: WebSocket;
    private readonly audioContext: AudioContext;
    private readonly chunkBucket: EventBucket<Buffer>;
    private readonly textBucket: EventBucket<Message>;
    private readonly syncText: boolean;
    private readonly removePrefix: string;
    private latestResponse?: Message;
    private charSchedule: number[];
    private durationToContextTime: {
        displayedDuration: number;
        atContextTime: number;
    }[];
    private sent: string;
    private finished: boolean;
    private stopped: boolean;

    constructor({
        voiceId,
        removePrefix,
        textBucket,
        syncText,
    }: {
        voiceId: string;
        removePrefix: string;
        textBucket: EventBucket<Message>;
        syncText: boolean;
    }) {
        this.ws = new WebSocket(
            `wss://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream-input?model_id=eleven_monolingual_v1`,
        );
        this.audioContext = new AudioContext();
        this.ws.onmessage = (event) => this.onmessage(JSON.parse(event.data));
        this.chunkBucket = new EventBucket();
        this.textBucket = textBucket;
        this.syncText = syncText;
        this.durationToContextTime = [];
        this.charSchedule = [];
        this.removePrefix = removePrefix;
        this.sent = removePrefix;
        this.finished = false;
        this.stopped = false;
    }

    say(response: Message) {
        if (!this.syncText) {
            this.textBucket.push(response);
        } else {
            this.latestResponse = response;
        }
        const text = response.text!;
        if (text.length > this.sent.length) {
            this.ws.send(
                JSON.stringify({
                    text: text.slice(this.sent.length, text.length),
                    voice_settings: {
                        stability: 0.8,
                        similarity_boost: true,
                    },
                    // "generation_config": {
                    //   "chunk_length_schedule": [120, 160, 250, 290]
                    // },
                    xi_api_key: configValue.elevenlabsKey!,
                    // "authorization": "Bearer <Authorization Token>"
                }),
            );
            this.sent = text;
        }
    }

    finish() {
        if (!this.syncText) {
            this.textBucket.close();
        }
        this.finished = true;
        this.ws.send(
            JSON.stringify({
                text: '',
            }),
        );
    }

    stop() {
        this.chunkBucket.close();
        this.textBucket.close();
        this.audioContext.close();
        this.stopped = true;
    }

    onmessage(data: any) {
        if (data.isFinal) {
            this.ws.close();
            this.chunkBucket.close();
        } else {
            const arrayBuffer = Buffer.from(data.audio, 'base64');
            this.chunkBucket.push(arrayBuffer);
            if (data.normalizedAlignment) {
                for (const charDuration of data.normalizedAlignment.charDurationsMs) {
                    this.charSchedule.push((this.charSchedule[this.charSchedule.length - 1] || 0) + charDuration);
                }
            }
        }
    }

    async runSyncLoop() {
        let charIndex = 0;
        let durationIndex = 0;
        let displayedDuration = 0;
        while (true) {
            if (this.stopped) {
                break;
            }
            await new Promise((r) => setTimeout(r, 500));
            if (!this.latestResponse || !this.charSchedule) {
                continue;
            }
            while (
                durationIndex < this.durationToContextTime.length &&
                this.durationToContextTime[durationIndex].atContextTime < this.audioContext.currentTime
            ) {
                displayedDuration = this.durationToContextTime[durationIndex].displayedDuration;
                durationIndex++;
            }
            while (charIndex < this.charSchedule.length && this.charSchedule[charIndex] < displayedDuration * 1000) {
                charIndex++;
            }
            this.textBucket.push({
                ...this.latestResponse!,
                text: this.latestResponse.text!.slice(0, this.removePrefix.length + charIndex),
            });
            if (this.finished && charIndex + this.removePrefix.length >= this.latestResponse.text!.length) {
                this.textBucket.close();
                break;
            }
        }
    }

    async runPlayLoop() {
        if (this.syncText) {
            this.runSyncLoop();
        }
        let nextTime = 0;
        let displayedDuration = 0;
        for await (const buffer of this.chunkBucket.iterator) {
            const audioBuffer = await this.audioContext.decodeAudioData(buffer.buffer);
            const source = this.audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(this.audioContext.destination);
            nextTime = Math.max(nextTime, this.audioContext.currentTime);
            source.start(nextTime);
            displayedDuration += source.buffer.duration;
            this.durationToContextTime.push({
                displayedDuration,
                atContextTime: nextTime,
            });
            nextTime += source.buffer.duration;
        }
    }
}

export class Reka {
    private readonly apiKey: string | null;
    private readonly accessToken: string | null;
    private readonly baseUrl: string;
    private tts?: TTS;

    constructor(apiKey: string | null, accessToken: string | null, baseUrl: string) {
        if (!apiKey && !accessToken) throw 'Authentication required';
        this.apiKey = apiKey;
        this.accessToken = accessToken;
        this.baseUrl = baseUrl;
        this.chat.bind(this);
        this.uploadMedia.bind(this);
        this.uploadFile.bind(this);
        this.uploadDataset.bind(this);
        this.prepareRetrieval.bind(this);
        this.getDatasets.bind(this);
        this.deleteDataset.bind(this);
    }

    stop() {
        this.tts?.stop();
    }

    chat({
        human,
        mediaUrl,
        mediaType,
        fileUrl,
        conversationHistory = [],
        retrievalDataset,
        modelName = 'reka-flash',
        randomSeed,
        useCodeInterpreter,
        useSearchEngine,
        requestOutputLen,
        temperature,
        runtimeTopK,
        runtimeTopP,
        repetitionPenalty,
        lenPenalty,
        stopTokens,
        assistantStartText,
    }: IChatParams) {
        const turn: Message = {
            type: 'human',
            text: human,
        };

        if (mediaUrl) {
            turn.image_url = mediaUrl;
            turn.media_type = mediaType;
        } else if (fileUrl) {
            turn.file_url = fileUrl;
        }

        const payload: PayloadType = {
            conversation_history: [...conversationHistory, turn],
            stream: false,
            use_search_engine: useSearchEngine,
            use_code_interpreter: useCodeInterpreter,
            model_name: modelName,
            random_seed: randomSeed ?? Date.now(),
            retrieval_dataset: retrievalDataset,
            request_output_len: requestOutputLen,
            temperature: temperature,
            runtime_top_k: runtimeTopK,
            runtime_top_p: runtimeTopP,
            repetitionPenalty: repetitionPenalty,
            len_penalty: lenPenalty,
            stop_tokens: stopTokens,
            assistant_start_text: assistantStartText,
        };

        // remove any metadata
        payload.conversation_history = payload.conversation_history.map(
            ({ type, text, image_url, media_type, file_url }) => ({ type, text, image_url, media_type, file_url }),
        );

        return this.getResponse<Message>(this.postJson('/chat', compact(payload)));
    }

    chatStream({
        human,
        mediaUrl,
        mediaType,
        fileUrl,
        conversationHistory = [],
        retrievalDataset,
        modelName = 'reka-flash',
        randomSeed,
        useCodeInterpreter,
        useSearchEngine,
        requestOutputLen,
        temperature,
        runtimeTopK,
        runtimeTopP,
        repetitionPenalty,
        lenPenalty,
        stopTokens,
        assistantStartText,
        persona,
    }: IChatParams) {
        const sseEndpoint = 'chat';
        const sseUrl = `${this.baseUrl}/${sseEndpoint}`;

        const turn: Message = {
            type: 'human',
            text: human,
        };

        if (mediaUrl) {
            turn.image_url = mediaUrl;
            turn.media_type = mediaType;
        } else if (fileUrl) {
            turn.file_url = fileUrl;
        }

        if (conversationHistory?.at(-1)?.type === 'human') {
            conversationHistory.pop();
        }

        const payload: PayloadType = {
            conversation_history: [...conversationHistory, turn],
            stream: true,
            use_search_engine: useSearchEngine,
            use_code_interpreter: useCodeInterpreter,
            model_name: modelName,
            random_seed: randomSeed ?? Date.now(),
            retrieval_dataset: retrievalDataset,
            request_output_len: requestOutputLen,
            temperature: temperature,
            runtime_top_k: runtimeTopK,
            runtime_top_p: runtimeTopP,
            repetitionPenalty: repetitionPenalty,
            len_penalty: lenPenalty,
            stop_tokens: stopTokens,
            assistant_start_text: assistantStartText,
        };

        const evtBucket = new EventBucket<Message>();
        if (persona) {
            payload.conversation_history = JSON.parse(JSON.stringify(payload.conversation_history));
            const firstTurn = payload.conversation_history[0];
            firstTurn.text = persona.systemPrompt + firstTurn.text;

            if (useSearchEngine) {
                if (payload.conversation_history.length > 1) {
                    const lastTurn = payload.conversation_history[payload.conversation_history.length - 1];
                    lastTurn.text = (persona.searchKeywords ? persona.searchKeywords + ', ' : '') + lastTurn.text;
                }
            }
            payload.assistant_start_text = persona.assistantStartText;
            this.tts = new TTS({
                voiceId: persona.voiceId,
                removePrefix: persona.assistantStartText,
                textBucket: evtBucket,
                syncText: true,
            });
            this.tts.runPlayLoop();
        }

        // remove any metadata
        payload.conversation_history = payload.conversation_history.map(
            ({ type, text, image_url, media_type, file_url }) => ({ type, text, image_url, media_type, file_url }),
        );

        const headers: any = {
            'Content-Type': 'application/json',
        };

        if (!this.apiKey) {
            headers['Authorization'] = `Bearer ${this.accessToken}`;
        } else {
            headers['X-api-key'] = this.apiKey;
        }

        const sse: any = new SSE(sseUrl, {
            headers,
            payload: JSON.stringify(compact(payload)),
            method: 'POST',
        });

        sse.addEventListener('message', (event: { data: string }) => {
            if (!event.data) return;
            try {
                const response: Message = JSON.parse(event.data);
                if (this.tts) {
                    this.tts.say(response);
                } else {
                    evtBucket.push(response);
                }

                // think about how to handle this better later
                if (!!response.finish_reason) {
                    if (this.tts) {
                        this.tts.finish();
                    } else {
                        evtBucket.close();
                    }
                }
            } catch (error: any) {
                // We get a valid message but cannot parse data. This should not happen but I suspect our SSE implementation
                // is decoding the event bodies wrongly
                console.error('Message event cannot be parsed', error, event);
                captureException(error, { level: 'error', extra: event });
            }
        });
        sse.addEventListener('error', (event: { data: string }) => {
            try {
                const { detail } = JSON.parse(event.data);
                evtBucket.push(new Error(detail));
            } catch {
                evtBucket.push(new Error(''));
            }
        });
        sse.addEventListener('readystatechange', (event: { readyState: number }) => {
            if (event.readyState === 2) {
                if (this.tts) {
                    this.tts.finish();
                } else {
                    evtBucket.close();
                }
            }
        });
        sse.stream();
        return evtBucket.iterator;
    }

    async speech2speech({ audio }: { audio: string }) {
        const request = this.postJson('/v1/voice_chat', {
            audio_base64: audio,
            language: 'english',
        });
        const { data, err } = await this.getResponse<{ audio_base64: string }>(request);
        return { data: data?.audio_base64, err };
    }

    async uploadMedia({ file }: { file: File }) {
        const formData = new FormData();
        formData.append('image', file);
        const request = this.post('/upload-image', formData);
        const { data, err } = await this.getResponse<{ image_url: string }>(request);
        return { data: data?.image_url, err };
    }

    async uploadFile({ file }: { file: File }) {
        const formData = new FormData();
        formData.append('file', file, file?.name);
        const request = this.post('/upload-file', formData);

        const { data, err } = await this.getResponse<{ file_url: string }>(request);
        return { data: data?.file_url, err };
    }

    async uploadFileV1({ file }: { file: File }) {
        const formData = new FormData();
        formData.append('file', file, file?.name);
        const request = this.post('/v1/files', formData);

        const { data, err } = await this.getResponse<{ file_url: string }>(request);
        return { data: data?.file_url, err };
    }

    async getFilesV1() {
        const request = this.get('/v1/files');

        const { data, err } = await this.getResponse<{ files: string[] }>(request);
        return { data, err };
    }

    async uploadDataset({ file, name, description }: { file: File; name: string; description?: string }) {
        const formData = new FormData();
        if (description) formData.append('dataset_description', description);
        formData.append('dataset_name', name);
        formData.append('file', file, file?.name);
        const request = this.post('/datasets', formData);
        const { data, err } = await this.getResponse<{ name: string }>(request);
        return { data: data?.name, err };
    }

    async *prepareRetrieval(name: string) {
        const prepareReq = this.post(`/datasets/${name}/prepare-retrieval`);
        const { data: jobId, err } = await this.getResponse<string>(prepareReq);
        let status = 'init';
        yield {
            jobId,
            status,
        };

        while (status !== 'COMPLETE' && status !== 'ERROR') {
            const statusReq = this.get(`/jobs/prepare-retrieval/${jobId}/status`);
            const { data, err } = await this.getResponse<{ job_status: string }>(statusReq);
            if (err) {
                status = 'ERROR';
            } else {
                status = data?.job_status!;
            }
            yield {
                jobId,
                status,
            };
            await new Promise((res) => setTimeout(res, 5000));
        }
    }

    getDatasets() {
        const request = this.get('/datasets');
        return this.getResponse<string[]>(request);
    }

    getDataset(name: string) {
        const request = this.get(`/datasets/${name}`);
        return this.getResponse<{ name: string; is_retrieval_prepared: boolean }>(request);
    }

    deleteDataset(name: string) {
        const request = this.delete(`/datasets/${name}`);
        return this.getResponse(request);
    }

    getAvailableModels() {
        const request = this.get('/models');
        return this.getResponse<string[]>(request);
    }
    postFeedback(thumbs_up: boolean, conversation_history: Message[]) {
        const request = this.postJson('/chat/feedback', {
            conversation_history,
            thumbs_up,
        });
        return this.getResponse<void>(request);
    }

    private async getResponse<T>(request: Promise<any>) {
        let response;
        let err: 'unsupported' | 'retry' | null = null;
        try {
            response = await request;
        } catch (e) {
            captureException(e);
            err = 'retry';
        }

        if (response?.status !== 200) err = 'retry';
        if (response?.status === 400) err = 'unsupported';

        if (!response || err) {
            return { data: null, err: err ?? 'unsupported' };
        }

        let data: T;

        try {
            data = await response.json();
        } catch (e) {
            captureException(e);
            return { data: null, err: 'unsupported' as const };
        }

        return { data, err: null };
    }

    private post(url: string, body: BodyInit | null = null) {
        return this.httpClient(url, {
            method: 'POST',
            body,
        });
    }
    private async postJson(url: string, body: Record<any, any>) {
        return this.httpClient(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
        });
    }
    private get(url: string) {
        return this.httpClient(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    private delete(url: string) {
        return this.httpClient(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    private async httpClient(url: string, options: RequestInit) {
        const headers: any = options.headers || {};
        if (!this.apiKey) {
            headers['Authorization'] = `Bearer ${this.accessToken}`;
        } else {
            headers['X-Api-Key'] = this.apiKey;
        }
        return fetch(this.baseUrl + url, {
            ...options,
            headers,
        });
    }
}

export class EventBucket<T> {
    private readonly _iterator: Generator<Promise<T>, void>;
    private queue: any[] = [];
    private resolve: any;
    private finished: boolean = false;

    constructor() {
        this._iterator = this.bucket();
    }

    get iterator() {
        return this._iterator;
    }

    push = (event: T | Error) => {
        if (this.resolve) {
            this.resolve(event);
        } else {
            this.queue.push(event);
        }
    };

    close = () => {
        this.finished = true;
        if (this.resolve) this.resolve();
    };

    private *bucket<T>(): Generator<Promise<T>, void> {
        while (this.queue.length > 0 || !this.finished) {
            yield new Promise((res, rej) => {
                this.resolve = (event: T | Error) => {
                    if (event instanceof Error) {
                        rej(event);
                    } else {
                        res(event);
                    }
                    this.resolve = null;
                };
                if (this.queue.length > 0) {
                    this.resolve(this.queue.shift());
                }
            });
        }
    }
}

class TokenManager {
    private accessToken?: string;
    private exp?: number;
    async getAccessToken() {
        if (!this.isValid()) {
            const res = await fetch('/bff/auth/access_token');
            const data: { accessToken: string } = await res.json();
            this.accessToken = data.accessToken;
            this.setExp(data.accessToken);
            return this.accessToken;
        } else {
            return this.accessToken;
        }
    }

    private setExp(token: string) {
        const claims = JSON.parse(window.atob(token.split('.')[1]));
        this.exp = claims?.exp;
    }

    private isValid() {
        return !!this.exp && Date.now() / 1000 > this.exp;
    }
}

export const TokenHelper = new TokenManager();

export async function rekaFactory(apikey: string | null) {
    if (!apikey) {
        const accessToken = await TokenHelper.getAccessToken();
        if (!accessToken) throw 'Authorization required';
        return new Reka(null, accessToken, configValue.baseURL);
    }
    return new Reka(apikey, null, configValue.baseURL);
}
